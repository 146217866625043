<template>
    <div ref="uiSelect" class="ui-select" :class="$attrs.class" v-click-outside="clickOutside">
        <button
            v-bind="{ ...$attrs, ...currentBindings }"
            type="button"
            class="ui-select__container"
            :class="{
                'ui-select__container--valid': field && field.$dirty && !field.$error,
                'ui-select__container--invalid': field && field.$dirty && field.$error,
            }"
            :disabled="loading || disabled"
            @click="focusInput"
            @keyup.enter="focusInput"
        >
            <div v-if="selectedOption && selectedOption.image" class="ui-select__option-image-container">
                <img :src="selectedOption.image" :alt="selectedOption[optionLabel]" class="ui-select__option-image" />
            </div>
            <div v-if="loading" class="py-4 text-center loading">
                <div class="spinner-border" style="width: 12px; height: 12px" role="status">
                    <span class="visually-hidden">Loading...</span>
                </div>
            </div>
            <div
                class="ui-select__label"
                :class="{
                    'ui-select__label--focus': value,
                    'ui-select__label--has-image': selectedOption && selectedOption.image,
                }"
            >
                {{ label }}
            </div>
            <div
                v-if="selectedOption"
                class="ui-select__value"
                :class="{
                    'ui-select__value--focus': value,
                    'ui-select__value--has-image': selectedOption && selectedOption.image,
                }"
            >

                {{ options.length > 0 ? selectedOption[this.optionLabel] : selectedOption }}
            </div>
        </button>
        <div v-show="showList" class="pb-5 list-wrapper ui-select__list-container">
            <div class="bg-white shadow rounded">
                <div class="p-2">
                    <input
                        ref="inputSearch"
                        class="ui-select__search-input"
                        type="text"
                        placeholder="Search"
                        autocomplete="off"
                        v-model="text"
                        @keypress.enter.prevent="selectOption"
                        @keydown="keyDown"
                    />
                </div>
                <div v-if="!options || !options.length" class="py-3 text-center text-muted">
                    No options available
                </div>
                <div v-else-if="!filteredOptions.length" class="p-3">
                    <p class="mb-0 text-muted">There is no option that matches your search</p>
                </div>
                <ul v-show="filteredOptions.length" ref="options" class="ui-select__list" @click="setValue">
                    <li v-if="showChooseOptionText">
                        <button type="button" class="ui-select__option">
                            {{ choseOptionText }}
                        </button>
                    </li>
                    <li v-for="item in filteredOptions" :key="item[optionValue]">
                        <button
                            type="button"
                            class="ui-select__option"
                            :class="{ 'ui-select__option--selected': item[optionValue] === value }"
                            :data-type="(typeof item[optionValue])"
                            :data-value="item[optionValue]"
                            :data-label="getDescendantProp(item, optionLabel)"
                        >
                            <slot name="option" v-bind="item">
                                <div v-if="item.image" class="ui-select__option-image-container">
                                    <img  :src="item.image" :alt="item[optionValue]" class="ui-select__option-image" />
                                </div>
                                <span class="ui-select__option-label">
                                    {{ getDescendantProp(item, optionLabel) }}
                                </span>
                            </slot>
                        </button>
                    </li>
                </ul>
            </div>
        </div>
        <div v-if="field && field.$error" class="ui-input__error">
            {{ field.$errors[0].$message }}
        </div>
    </div>
</template>

<script>
export default {
    inheritAttrs: false,
    props: {
        choseOptionText: {
            type: String,
            default: 'Choose an option',
            required: false,
        },
        disabled: {
            type: Boolean,
            default: false,
            required: false,
        },
        field: {
            type: Object,
            default: null,
            required: false,
        },
        label: {
            type: String,
            default: 'Choose an option',
            required: false,
        },
        loading: {
            type: Boolean,
            default: false
        },
        optionLabel: {
            type: [Array, String],
            default: 'label',
        },
        options: {
            type: [Object, Array],
            default: () => [],
        },
        optionValue: {
            type: String,
            default: 'value',
        },
        showChooseOptionText: {
            type: Boolean,
            default: true,
            required: false,
        },
        state: {
            type: Boolean,
            default: null,
        },
        value: {
            type: [String, Array, Object, Number],
            default: null,
        },                
    },
    data() {
        return {
            text: '',
            showList: false,
            indexSelect: 0,
        };
    },
    computed: {
        getLabel() {
            try {
                if (this.loading) return 'Loading...';

                let currentValue = this.options.find(i => i[this.optionValue] == this.value);
                currentValue = (currentValue && this.getDescendantProp(currentValue, this.optionLabel)) || this.value;
                return this.$attrs.labelForced || currentValue || this.value || 'Choose an option';
            } catch (error) {
                return this.$attrs.labelForced || this.value || 'Choose an option';
            }
        },
        selectedOption() {
            if (this.options.length > 0) {
                return this.options.find(item => item[this.optionValue] === this.value) || null;
            }else {
                return this.value || null;
            }
        },
        filteredOptions() {
            try {
                if (!this.text) return this.options;
                let counter = 0;
                return this.options.filter(option => {
                    if (counter > 30) return false;
                    const regex = new RegExp(this.text, 'ig');
                    const result = this.getDescendantProp(option, this.optionLabel).match(regex);
                    if (result) counter += 1;
                    return result;
                });
            } catch (error) {
                return [];
            }
        },
    },
    mounted() {
        if (this.$attrs.value) {
            this.$emit('update:value', this.$attrs.value);
        }
    },
    methods: {
        getDescendantProp(element, props) {
            if (Array.isArray(props)) {
                let newTag = '';
                for (const prop of props) {
                    const str = prop.split('.').reduce((a, b) => a[b], element);
                    newTag = `${newTag} ${str}`;
                }
                return newTag;
            }

            return props.split('.').reduce((a, b) => a[b], element);
        },
        clickOutside() {
            this.showList = false;
        },
        focusInput() {
            this.showList = true;
            this.text = '';
            setTimeout(() => {
                this.$refs.inputSearch.focus();
            }, 100);
        },
        setValue(event) {
            let data = event.target.dataset;
            if (!Object.entries(data).length) data = event.target.parentNode.dataset;

            data = { ...data };

            if (data.type === 'number') data.value = parseFloat(data.value);

            this.changeValue(data);
        },
        changeValue(data) {
            this.text = data.label;
            this.$emit('update:value', data.value);
            this.$emit('change', data.value);
            this.showList = false;
        },
        selectOption() {
            try {
                const list = this.$refs.uiSelect.querySelector('ul');
                const li = list.querySelector('.selected');
                const button = li.querySelector('button');
                this.changeValue(button.dataset);
            } catch (error) {
                return false;
            }
        },
        keyDown(event) {
            try {
                if (event.key === 'ArrowDown') {
                    const max = this.filteredOptions.length - 1;
                    this.indexSelect += 1;
                    this.indexSelect = this.indexSelect <= max ? this.indexSelect : max;
                    this.scrollToSelected({ key: 'down' });
                } else if (event.key === 'ArrowUp') {
                    this.indexSelect -= 1;
                    this.indexSelect = this.indexSelect >= 0 ? this.indexSelect : 0;
                    this.scrollToSelected({ key: 'up' });
                } else if (event.key === 'Backspace') {
                    this.showList = true;
                } else if (event.key === 'Tab') {
                    this.showList = false;
                } else if (event.key !== 'Enter') {
                    this.indexSelect = 0;
                }
                this.$refs.options.children[this.indexSelect].scrollIntoView({
                    block: 'nearest',
                });
            } catch (error) {
                console.error(error);
            }
        },
        scrollToSelected() {
            try {
                if (!this.showList) return;
                this.$refs.options.children[this.indexSelect].scrollIntoView({
                    block: 'nearest',
                });
            } catch (error) {
                this.indexSelect = 0;
                console.error(error);
            }
        },
    },
};
</script>

<style lang="scss">
.ui-select {
    position: relative;
    min-width: 120px;
    max-width: 100%;

    &__container {
        align-items: center;
        background-color: $general-white;
        border: 1px solid $ecart-secondary-200;
        border-radius: 8px;
        display: flex;
        height: 40px;
        position: relative;
        padding: 0;
        width: 100%;

        &::after {
            color: $ecart-accent;
            content: '\f078';
            font-family: 'Font Awesome 5 Free', sans-serif;
            font-size: 16px;
            font-weight: 900;
            line-height: 1;
            position: absolute;
            right: 8px;
            top: calc(50% - 8px);
        }

        &:focus-within {
            border-color: $ecart-secondary-200;
            box-shadow: none;
        }

        &:disabled {
            background-color: $ecart-secondary-100;
        }

        &--valid {
            border-color: $general-success;

            &:focus-within {
                border-color: $general-success;
                box-shadow: none;
            }
        }

        &--invalid {
            border-color: $general-error;

            &:focus-within {
                border-color: $general-error;
                box-shadow: none;
            }
        }

        &--has-prepend {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }
    }

    &__label {
        font-size: 14px;
        font-weight: 400;
        color: $ecart-secondary-400;
        line-height: 1;
        position: absolute;
        left: 8px;
        top: calc(50% - 7px);
        transition: 200ms all;

        &--focus {
            color: $general-black;
            font-size: 12px;
            top: 4px;
        }

        &--has-image {
            left: 33.5px;
        }
    }

    &__value {
        color: $general-black;
        font-size: 14px;
        font-weight: 500;
        height: 100%;
        line-height: 1;
        overflow: hidden;
        padding: 17px 32px 4.5px 8px;
        text-align: left;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: calc(100%);

        &--has-image {
            padding-left: 0;
        }
    }

    &__search-input {
        border: 1px solid $ecart-secondary-200;
        border-radius: 8px;
        padding: 4.5px 8px;
        width: 100%;

        &:focus {
            border-color: #bddb91;
            box-shadow: 0 0 0 0.2rem rgba(#81b23a, 0.25);
        }
    }

    &__option {
        display: flex;
        padding: 0.5rem 1rem;
        color: $general-black;
        background: none;
        width: 100%;
        text-align: left;
        border: none;

        &:hover, &--selected {
            background-color: #f2f7eb !important;
            color: #81b23a !important;
        }

    }

    &__option-image-container {
        border: 1px solid $ecart-secondary-200;
        border-radius: 6px;
        flex-shrink: 0;
        height: 24px;
        margin-right: 5px;
        overflow: hidden;
        width: 24px;

        margin-left: 4.5px;
    }

    &__option-image {
        height: 100%;
        object-fit: cover;
        object-position: center;
        width: 100%;
    }

    &__option-label {
        flex-shrink: 0;
    }

    &__list-container {
        position: absolute;
        z-index: 99;
        width: 100%;
        left: 0;
    }

    &__list {
        overflow-y: auto;
        padding: 0;
        margin-bottom: 0;
        list-style: none;
        max-height: 250px;
    }

    &__error {
        color: $general-error;
        font-size: 0.9rem;
        margin-top: 5px;
    }
}

.loading {
    position: absolute;
    right: 30px;
    top: -19.5px;
}

.badge {
    font-size: 12px;
    margin: 0.2rem;
    padding: 0.4rem 0.55rem;

    & em {
        margin-left: 0.2rem;
    }
}
</style>
