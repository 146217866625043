'use strict';
import { view } from '@/functions';

const prefix = '/invoices';

export default [
    {
        path: prefix,
        name: 'invoices',
        meta: {
            scopes: ['read_invoices', 'write_invoices']
        },
        component: () => view('invoices/index'),
    },
];