import currencyFormatter from 'currency-formatter';

export default {
    methods: {
        _currency(value, currency = 'USD', showCurrency = true) {
            if (isNaN(value)) return value;
            value = parseFloat(value);
        
            value = currencyFormatter.format(value, { code: currency, precision: 2 });
            if (showCurrency) value = `${value} ${currency}`;
            return value;
        }
    }
};
