import BTable from './Table.vue';
import BPagination from './Pagination.vue';
import BDropdown from './components/Dropdown/Dropdown.vue';
import BDropdownItem from './components/Dropdown/DropdownItem.vue';
import BButton from './components/Button/Button.vue';
import BModal from './components/Modal/Modal.vue';
import BInput from './components/Input/Input.vue';

// Exportación de los componentes individualmente
export default { BTable, BPagination, BDropdown, BDropdownItem, BButton, BModal, BInput };
