'use strict';

import { view } from '@/functions';

const prefix = '/transactions';
export default [
	{
		path: prefix,
		name: 'transactions',
		meta: {
			scopes: ['read_transactions', 'write_transactions']
		},
		component: () => view('transactions/index')
	},

	{
		path: `${prefix}/info`,
		name: 'transactions-info',
		meta: {
			scopes: ['read_transactions', 'write_transactions']
		},
		component: () => view('transactions/info')
	},
]; 