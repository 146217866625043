export default {
  methods: {
    _getCookie(cookie) {
      const name = cookie + '=';
      const c_decoded = decodeURIComponent(document.cookie);
      const c_arr = c_decoded.split('; ');
      let res;
      c_arr.forEach((val) => {
        if (val.indexOf(name) === 0) {
          res = val.substring(name.length);
        }
      });
      return res;
    },

    _setCookie(c_name, c_value, exp_time) {
      if (!exp_time) {
        document.cookie = `${c_name}=${c_value}; path=/`;
        return;
      }

      let date = new Date(exp_time);
      const expires = 'expires=' + date.toUTCString();
      document.cookie = c_name + '=' + c_value + '; ' + expires + '; path=/';
    },

    _deleteCookie(c_name) {
      document.cookie = `${c_name}=; expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/`;
    },
  },
};
