'use strict';

import { view } from '@/functions';

const prefix = '/risks';

export default [
    {
        path: prefix,
        name: 'risks',
        meta: {
            scopes: ['read_risks']
        },
        component: () => view('risks/index'),
    },
]

