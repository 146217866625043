import cookies from '@/mixins/cookies.mixin';

const generateDeviceIdentifier = () => {
    const device_identifier = cookies.methods._getCookie('_aedid');

    if (!device_identifier) {
        const device_identifier = crypto.randomUUID();
        cookies.methods._setCookie('_aedid', device_identifier, Infinity);
    }
};

generateDeviceIdentifier();
