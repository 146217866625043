import { createStore } from 'vuex';

import accounts from '@/store/modules/accounts';
import billingInformation from '@/store/modules/dynamic-forms/billing-information';
import filters from '@/store/modules/filters';
import globalFilters from '@/store/modules/global-filters';
import activations from '@/store/modules/activations';

export default createStore({
    modules: {
        accounts,
        billingInformation,
        filters,
        globalFilters,
        activations
    },
});
