import axios from 'axios';
import { decrypt } from '@tendencys/encrypter';
import cookies from '@/mixins/cookies.mixin';
import accountsFilter from '../data/global-filters';

const client = axios.create({
	headers: {
		'content-type': 'application/json',
	}
});

client.interceptors.request.use((config) => {
	const searchParams = new URLSearchParams(window.location.search);
	const params = Object.fromEntries(searchParams.entries());
	let token = decrypt(cookies.methods._getCookie('_tid'), process.env.VUE_APP_TENCRYPT);

	if (params._v) {
		const queryData = JSON.parse(decrypt(params._v, process.env.VUE_APP_TENCRYPT));
		token = queryData.token || token;
	}

	if (token) {
		config.headers.authorization = token;
	}

	if (accountsFilter.includes(config.url)) {
		const filters = JSON.parse(localStorage.getItem('globalFilters'));
		if (filters !== null && filters.account && filters.account !== 0 && filters.account !== undefined) {
			config.params.account_id = filters.account.id;
		}
	}
	return config;
});

client.interceptors.response.use(undefined, error => {
	const public_routes = [
		'/login',
		'/update-password',
		'/invitation-sign-up',
	];

	const status = error?.response?.status;
	const status_code = error?.response?.data?.statusCode;
	const message = error?.response?.data?.message;

	if (
		(
			status === 401
			|| status_code === 401
			|| (status_code === 422 && message === 'JWT malformed.')
		)
		&& !public_routes.includes(window.location.pathname)
	) {
		logout();
		cookies.methods._deleteCookie('_tid');
		window.location.href = '/login';
	}
	return Promise.reject(error);
});

const login = (data) => {
	return client.post('/_/signin', {}, {
		headers: {
			Authorization: 'Basic ' + Buffer.from(data.email + ':' + data.password).toString('base64')
		}
	});
};

const recoveryPassword = (data) => {
	return client.post('/_/send-recovery-password', {}, {
		headers: {
			Authorization: 'Basic ' + Buffer.from(data.email + ':' + data.password).toString('base64')
		}
	});
};

const logout = () => {
	return axios.get('/_/logout');
};

export default {
	client,
	login,
	logout,
	recoveryPassword,
	
};