import { mapGetters, mapActions, mapMutations } from 'vuex';

export default {
    computed: {
        ...mapGetters('accounts', ['hasScopes']),
        ...mapGetters('accounts', ['hisRole'])
    },
    methods: {
        ...mapActions('accounts', ['getAccount']),
        ...mapMutations('accounts', ['setAccount', 'setScopes']),
        CAN(required_scopes, required_role) {
            let show = true;
            if (this.hisRole === 'superadmin') {
                return show;
            }
            if (!Array.isArray(required_scopes)) {
                return !show;
            }
            let has_scopes = required_scopes.some((scope) => this.hasScopes.includes(scope));
            if (!has_scopes) {
                return !show;
            }
            if (required_role == undefined && has_scopes) {
                return show;
            }
            if (required_role !== this.hisRole) {
                return !show;
            }
            return show;
        }
    }
}