import axios from 'axios';
import cookies from '@/mixins/cookies.mixin';

const state = () => ({
    available_filters: null,
});

const actions = {
    async getFilters({ commit }) {
        const { data } = await axios.get('/_/transactions/filters');
        commit('setFilters', data);
    },
};

const mutations = {
    setFilters(state, filters) {
        state.available_filters = filters;
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
};
