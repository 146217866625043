'use strict';

import { view } from '@/functions';

const prefix = '/reports';
export default [
    {
        path: prefix,
        name: 'reports',
        meta: {
            scopes: ['read_reports', 'report_accounts']
        },
        component: () => view('reports/index'),
    },
];
