'use strict';

import { view } from '@/functions';

const prefix = '/profiles';
export default [
	{
		path: prefix,
		name: 'profiles',
		meta: {
			scopes: ['read_profiles', 'write_profiles']
		},
		component: () => view('profiles/index'),
	},

	{
		path: `${prefix}/info`,
		name: 'profiles-info',
		meta: {
			scopes: ['read_profiles', 'write_profiles']
		},
		component: () => view('profiles/info'),
	},
	{
		path: `${prefix}/register`,
		name: 'profiles-create',
		meta: {
			scopes: ['write_profiles']
		},
		component: () => view('profiles/register'),
	},
	{
		path: `${prefix}/edit`,
		name: 'profiles-edit',
		meta: {
			scopes: ['write_profiles']
		},
		component: () => view('profiles/edit'),
	},
]; 