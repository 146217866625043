'use strict';

import { view } from '@/functions';

const prefix = '/invitation';
export default [
    { 
        path: `${prefix}/sign-up`, 
        name: 'invitation-sign-up',
        component: () => view('invitation/sign-up'),
        meta: {
            layout: 'Blank',
        } 
    }
];
