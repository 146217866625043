'use strict';

import { view } from '@/functions';

const prefix = '/webhook-logs';
export default [
    {
        path: prefix,
        name: 'webhook-logs',
        meta: {
            scopes: ['read_webhookslogs', 'write_webhookslogs']
        },
        component: () => view('webhook-logs/index')
    },
];
