<template>
    <div class="ui-input">
        <div class="d-flex">
            <div v-if="!!$slots.prepend" class="ui-input__prepend">
                <slot name="prepend"></slot>
            </div>
            <div
                class="ui-input__container"
                :class="{
                    'ui-input__container--valid': field && field.$dirty && !field.$error,
                    'ui-input__container--invalid': field && field.$dirty && field.$error,
                    'ui-input__container--has-prepend': !!$slots.prepend,
                    'ui-input__container--has-append': !!$slots.append,
                }"
            >
                <input
                    :id="id"
                    :name="name"
                    :type="type"
                    :placeholder="placeholder"
                    :value="value"
                    :disabled="disabled"
                    :min="min"
                    :max="max"
                    :autocomplete="autocomplete"
                    class="ui-input__input"
                    novalidate="true"
                    @input="changeValue"
                    @click="handleCancelClick"
                />
                <label :for="id" class="ui-input__label">
                    {{ label }}
                </label>
                <div v-if="clearOptions && value !== ''" class="ui-input__clear-container">
                    <button class="ui-input__clear-button" @click="clearSelectedOptions" :disabled="disabled">
                        <svg width="18" height="18" viewBox="0 0 20 20">
                            <path
                                d="M10 10l5.09-5.09L10 10l5.09 5.09L10 10zm0 0L4.91 4.91 10 10l-5.09 5.09L10 10z"
                                stroke="currentColor"
                                fill="none"
                                fill-rule="evenodd"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            ></path>
                        </svg>
                    </button>
                </div>
            </div>
            <div v-if="!!$slots.append" class="ui-input__append">
                <slot name="append"></slot>
            </div>
        </div>
        <div v-if="field && field.$error" class="ui-input__error">
            {{ field.$errors[0].$message }}
        </div>
    </div>
</template>

<script>
export default {
    name: 'UiInput',
    props: {
        autocomplete: {
            type: String,
            default: null,
            required: false
        },
        debounce: {
            type: Number,
            default: 0,
            required: false,
        },
        disabled: {
            type: Boolean,
            default: false,
            required: false,
        },
        field: {
            type: Object,
            default: null,
            required: false,
        },
        id: {
            type: String,
            required: true,
        },
        label: {
            type: String,
            required: true,
        },
        min: {
            type: String,
            required: false,
            default: null,
        },
        max: {
            type: String,
            required: false,
            default: null,
        },
        name: {
            type: String,
            required: true,
        },
        placeholder: {
            type: String,
            default: ' ',
            required: false,
        },
        type: {
            type: String,
            default: 'text',
            required: true,
        },
        value: {
            type: [String, Number],
            default: '',
            required: true,
        },
        icon: {
            type: String,
            default: null,
            required: false,
        },
        clearOptions: {
            type: Boolean,
            default: false,
            required: false,
        },
    },
    methods: {
        changeValue(event) {
            const value = event.target.value;

            clearTimeout(this.timer);

            if (this.debounce === 0) {
                this.$emit('update:value', value);
            } else {
                this.timer = setTimeout(() => {
                    this.$emit('update:value', value);
                }, this.debounce);
            }
        },
        handleCancelClick(event) {
            if (this.type === 'search' && event.target.value != '') {
                this.$emit('search:cancel');
            }
        },
        clearSelectedOptions() {
            this.$emit('clearSearch');
        },
    },
};
</script>

<style lang="scss">
.ui-input {
    &__prepend {
        background-color: $ecart-primary;
        border-radius: 8px 0px 0px 8px;
        color: #ffffff;
        display: flex;
        position: relative;
        justify-content: center;
        align-items: center;
        padding: 4.5px 8px;
    }

    &__append {
        background-color: $ecart-primary;
        border-radius: 0px 8px 8px 0px;
        color: #ffffff;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 4.5px 8px;
    }

    &__container {
        background-color: $general-white;
        border: 1px solid $ecart-secondary-200;
        border-radius: 8px;
        display: flex;
        flex-wrap: wrap;
        height: 40px;
        position: relative;
        width: 100%;

        &:focus-within {
            border-color: $ecart-secondary-200;
            box-shadow: none;
        }

        &--valid {
            border-color: $general-success;

            &:focus-within {
                border-color: $general-success;
                box-shadow: none;
            }
        }

        &--invalid {
            border-color: $general-error;

            &:focus-within {
                border-color: $general-error;
                box-shadow: none;
            }
        }

        &--has-prepend {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }

        &--has-append {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }
    }

    &__label {
        margin-bottom: 0;
        font-size: 14px;
        font-weight: 400;
        color: $ecart-secondary-400;
        line-height: 1;
        position: absolute;
        left: 8px;
        top: calc(50% - 7px);

        transition: 200ms all;
    }

    &__clear-container {
        position: absolute;
        right: 8px;
        top: 50%;
        transform: translateY(-50%);
        display: flex;
        align-items: center;
    }

    &__clear-button {
        align-items: center;
        border: none;
        border-radius: 100%;
        background-color: transparent;
        color: $ecart-secondary-500;
        display: flex;
        flex-shrink: 0;
        height: 22px;
        justify-content: center;
        margin-right: 2px;
        padding: 2px;
        transition: 200ms all;
        width: 22px;

        &:hover {
            background-color: $ecart-secondary-100;
        }
    }

    &__input {
        background-color: transparent;
        background-image: none;
        border: none;
        border-radius: 8px;
        color: $general-black;
        font-size: 14px;
        font-weight: 500;
        height: 100%;
        line-height: 1;
        padding: 4.5px 8px;
        padding-top: 15px;
        width: 100%;
        padding-right: 2.5rem; 

        &::placeholder {
            opacity: 0;
            color: $ecart-secondary-400;
            font-weight: 400;
        }

        &:focus {
            background-color: transparent;

            &::placeholder {
                transition: opacity 200ms ease-in;
                opacity: 1;
            }
        }

        &:disabled {
            background-color: $ecart-secondary-100;
        }

        &:-webkit-autofill,
        &:-webkit-autofill:hover, 
        &:-webkit-autofill:focus, 
        &:-webkit-autofill:active  {
            -webkit-background-clip: text;
        }
    }

    &__error {
        color: $general-error;
        font-size: 0.9rem;
        margin-top: 5px;
    }
}

.ui-input__input:focus ~ .ui-input__label,
.ui-input__input:not(:placeholder-shown) ~ .ui-input__label {
    color: $general-black;
    font-size: 12px;
    top: 4px;
}

.ui-input__input:focus,
.ui-input__input:not(:placeholder-shown) {
    bottom: 4px;
    top: auto;
}

.ui-input__input::-webkit-search-cancel-button {
    display: block;
    -webkit-appearance: none;
    height: 2em;
    width: 2em;
    background: url('data:image/svg+xml;utf8,<svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"/></svg>') 50% 50% no-repeat;
    background-size: 1.1em;
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    padding: 0;
    border: none;
    opacity: 0.5;
    transition: opacity 0.3s;    
}
</style>
