<template>
    <button :class="[variantClass]" type="button" class="btn" :disabled="loading">
        <slot></slot>
        <div v-if="loading" class="spinner-border text-light spinner-border-sm ms-2 align-middle" role="status">
            <span class="visually-hidden">Loading...</span>
        </div>
    </button>
</template>

<script>
export default {
    name: 'BButton',
    props: {
        variant: { type: String, default: 'primary' },
        loading: { type: Boolean, default: false },
    },
    data() {
        return {
            variantClass: `btn-${this.variant}`,
        };
    },
};
</script>