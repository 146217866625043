'use strict'

import { view } from '@/functions';
const prefix = '/two-step-auth'

export default [
	{
		path: prefix,
		name: 'two-step-auth',
		component: () => view('twofactorauth/two-factor-auth'),
		meta: {
            layout: 'Blank'
        } 
	},
	{
		path: `${prefix}/register`,
		name: 'register-auth',
		component: () => view('twofactorauth/register-auth'),
		meta: {
			layout: 'Blank'
		}
	}
]