'use strict';

import { view } from '@/functions';

const prefix = '/refunds';

export default [
    {
        path: prefix,
        name: 'refunds',
        meta: {
            scopes: ['read_refunds', 'write_refunds']
        },
        component: () => view('refunds/index'),
    },
    {
        path: `${prefix}/info`,
        name: 'refunds-info',
        meta: {
            scopes: ['read_refunds', 'write_refunds']
        },
        component: () => view('refunds/info'),
    }
]

