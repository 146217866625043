const SET_COUNTRY = (state, payload) => {
    state.country = payload;
};

const SET_ACCOUNT = (state, payload) => {
    state.account = payload;
};

export default {
    SET_COUNTRY,
    SET_ACCOUNT
};
