<template>
    <nav v-if="pages > 1" aria-label="Page navigation example">
        <ul class="pagination" :class="getSizeButtons">
            <li class="page-item" :class="modelValue === 1 && 'disabled'">
                <button class="page-link" aria-label="First" @click="changePage(1)">
                    <span aria-hidden="true">&laquo;</span>
                </button>
            </li>
            <li class="page-item" :class="modelValue === 1 && 'disabled'">
                <button class="page-link" aria-label="Previous" @click="changePage(modelValue - 1)">
                    <span aria-hidden="true">&lsaquo;</span>
                </button>
            </li>
            <li v-show="modelValue - 1 > 0" v-for="i in 1" :key="i" class="page-item" @click="changePage(modelValue - i)">
                <button class="page-link" :aria-posinset="modelValue">
                    {{ modelValue - i }}
                </button>
            </li>
            <li class="page-item active">
                <span class="page-link" :aria-posinset="modelValue">
                    {{ modelValue }}
                </span>
            </li>
            <template v-if="modelValue < pages">
              <li v-for="i in 1" :key="i" class="page-item" @click="changePage(i + modelValue)">
                  <button class="page-link" :aria-posinset="modelValue">
                      {{ i + modelValue }}
                  </button>
              </li>
            </template>

            <li class="page-item" :class="modelValue === pages && 'disabled'">
                <button class="page-link" aria-label="Next" @click="changePage(modelValue + 1)">
                    <span aria-hidden="true">&rsaquo;</span>
                </button>
            </li>
            <li class="page-item" :class="modelValue === pages && 'disabled'">
                <button class="page-link" aria-label="Last" @click="changePage(pages)">
                    <span aria-hidden="true">&raquo;</span>
                </button>
            </li>
        </ul>
    </nav>
</template>

<script>
export default {
    name: 'BPagination',
    props: {
        modelValue: {
            type: Number,
            default: 1,
        },
        pages: {
            type: Number,
            default: 1,
        },

        size: {
            type: String,
            default: null,
        },
    },
    computed: {
        getSizeButtons() {
            return this.size ? `pagination-${this.size}` : '';
        },
    },
    methods: {
        changePage(page) {
            this.$emit('update:modelValue', page);
        },
    },
};
</script>
