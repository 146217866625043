const saveGlobalFilter = (key, value) => {
    const globalFilters = JSON.parse(localStorage.getItem('globalFilters')) || {};
    localStorage.setItem('globalFilters', JSON.stringify({ ...globalFilters, [key]: value }));
};

const setCountry = ({ commit }, payload) => {
    saveGlobalFilter('country', payload);
    commit('SET_COUNTRY', payload);
};

const setAccount = ({ commit }, payload) => {
    saveGlobalFilter('account', payload);
    commit('SET_ACCOUNT', payload);
};

export default {
    setCountry,
    setAccount
};
