<template>
    <div 
        ref="modal"
        class="ui-modal modal"
        aria-hidden="true"
        tabindex="-1"
        :class="{ fade: fade }"
        :aria-labelledby="title || $slots.title"
        :data-bs-backdrop="backdrop"
        :data-bs-keyboard="keyboard"
    >
        <div
            class="modal-dialog"
            :class="[
                { 'modal-dialog-centered': centered },
                `modal-${size}`,
            ]"
        >
            <div class="modal-content">
                <div class="ui-modal__modal-header modal-header">
                    <slot name="header">
                        <h5 class="modal-title">
                            {{ title }}
                        </h5>
                    </slot>
                    <button
                        v-if="!hideHeaderClose"
                        class="ui-modal__close-button"
                        type="button"
                        aria-label="Close"
                        @click="hide"
                    >
                        <span class="fas fa-times"></span>
                    </button>
                </div>
                <div class="modal-body">
                    <slot></slot>
                </div>
                <div class="ui-modal__modal-footer modal-footer">
                    <slot name="footer"></slot>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import modalsMixin from '@/mixins/modals.mixin';

export default {
    name: 'UiModal',
    mixins: [modalsMixin],
    props: {
        backdrop: {
            type: [Boolean, String],
            default: true,
            required: false
        },
        centered: {
            type: Boolean,
            default: false,
            required: false,
        },
        fade: {
            type: Boolean,
            default: true,
            required: false,
        },
        hideHeaderClose: {
            type: Boolean,
            default: false,
            required: false,
        },
        title: {
            type: String,
            default: null,
            required: false
        },
        keyboard: {
            type: Boolean,
            default: true,
            required: false,
        },
        size: {
            type: String,
            default: 'md',
            required: false,
        },
    },
}
</script>

<style lang="scss">
.ui-modal {

    &__modal-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: none;
    }

    &__close-button {
        margin-left: auto;
        border: none;
        background: none;
    }

    &__modal-footer {
        border-top: none;
    }
}
</style>
