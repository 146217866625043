const stages = [
    {
        label: 'Not available',
        value: 'NA',
    },
    {
        label: 'Contact / With tour',
        value: 'CO',
    },
    {
        label: 'Contact / No tour',
        value: 'CN',
    },
    {
        label: 'Proposal',
        value: 'PR',
    },
    {
        label: 'Negotiation / Review',
        value: 'NR',
    },
    {
        label: 'Closed / Won',
        value: 'CW',
    },
    {
        label: 'Closed / Lost',
        value: 'CL',
    },
];

export default stages;
