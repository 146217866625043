<template>
    <component
        class="ui-link"
        v-sanitize-href="link"
        :target="target"
        :to="to"
        :is="tag"
        :class="[
            variant ? `ui-link--${variant}` : '',
            weight ? `ui-link--${weight}` : '',
            { 'ui-link--underline': underline }
        ]"
    >
        <slot></slot>
    </component>
</template>

<script>
export default {
    name: 'UiLink',
    props: {
        link: {
            type: String,
            default: null,
            required: false,
        },
        target: {
            type: String,
            default: null,
            required: false,
        },
        to: {
            type: [String, Object],
            default: null,
            required: false,
        },
        variant: {
            type: String,
            default: 'primary',
            required: false,
        },
        weight: {
            type: String,
            default: 'bold',
            required: false,
        },
        underline: {
            type: Boolean,
            default: false,
            required: false,
        }
    },
    data() {
        return {
            tag: 'button',
        };
    },
    created() {
        if (this.to) {
            this.tag = 'router-link';
        } else {
            this.tag = 'a';
        }
    },
};
</script>

<style lang="scss" scoped>
.ui-link {
    text-decoration: none;
    transition: 500ms all;
    
    &--primary {
        color: $ecart-accent;

        &:hover {
            color: rgba($ecart-accent, 0.7);
        }
    }

    &--secondary {
        color: $ecart-secondary-600;

        &:hover {
            color: rgba($ecart-secondary-600, 0.7);
        }
    }

    &--global {
        color: $ecart-primary;

        &:hover {
            color: rgba($ecart-primary, 0.7);
        }
    }

    &--regular {
        font-weight: 400;
    }

    &--medium {
        font-weight: 500;
    }

    &--bold {
        font-weight: 700;
    }

    &--underline {
        text-decoration: underline;
    }
}
</style>
