<template>
    <div class="table-responsive">
        <table class="table mb-0" :class="[stackedClass, alignClass]">
            <thead>
                <tr>
                    <th v-for="f in fields" :key="f.key">{{ f.label }}</th>
                </tr>
            </thead>
            <tbody v-if="busy">
                <tr>
                    <td class="loading-cell" :colspan="fields ? fields.length : 1">
                        <div class="spinner-border" style="width: 3rem; height: 3rem" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                    </td>
                </tr>
            </tbody>
            <tbody v-else-if="!items || !items.length">
                <tr>
                    <td class="loading-cell" :colspan="fields ? fields.length : 1">No records</td>
                </tr>
            </tbody>
            <tbody v-else>
                <template v-for="(item, index) in items" :key="index">
                    <tr>
                        <td class="border-0" v-for="f in fields" :key="f.key" :data-label="f.label">
                            <div>
                                <slot :name="`cell(${f.key})`" :item="item">{{ item[f.key] }}</slot>
                            </div>
                        </td>
                    </tr>
                    <tr v-if="$slots.cellFull">
                        <td class="col-full" :colspan="fields.length">
                            <slot :name="`cellFull`" :item="item"></slot>
                        </td>
                    </tr>
                </template>
            </tbody>
        </table>
    </div> 
</template>

<script>
export default {
    name: 'BTable',
    props: {
        items: {
            type: Array,
            default: null,
        },
        fields: {
            type: Array,
            default: null,
        },
        busy: {
            type: Boolean,
            default: false,
        },
        stacked: {
            type: String,
            default: 'lg'
        },
        align: {
            type: String,
            default: 'middle',
        }
    },
    data() {
        return {
            stackedClass: this.stacked ? `table-stacked-${this.stacked}` : '',
            alignClass: `align-${this.align}`,
        };
    },
};
</script>

<style lang="scss" scoped>
.loading-cell {
    padding: 50px 15px;
    text-align: center;
}

.table-stacked-lg {
    @include media-breakpoint-down(lg) {
        thead {
            display: none;
        }
        tbody {
            border-top: 0;
        }
        tr:not(:first-child) td:first-child {
            border-top: 1px solid $gray-200;
        }
        tr {
            display: flex;
            flex-direction: column;
            td {
                display: flex;
                border-bottom: 0;
            }
            td > div {
                width: 60%;
                text-align: right;
            }
            td::before {
                content: attr(data-label);
                display: block;
                width: 40%;
                font-size: 14px;
                font-weight: 600;
            }
        }
    }
}

.col-full {
    padding: 0;
}
</style>