<template>
    <div class="mb-3" :class="[parentClass, { row: horizontal }]">
        <label
            v-if="$slots.label || label"
            :for="id"
            class="form-label"
            :class="[
                classLabel,
                {
                    [labelColClass]: horizontal,
                },
            ]"
            ><slot name="label">{{ label }}</slot></label
        >
        <div
            :class="{
                'ms-auto': horizontal,
                [inputColClass]: horizontal,
            }"
        >
            <input v-bind="{ ...$attrs }" :id="id" class="form-control" :class="[classSize]" @input="changeValue" />
        </div>
    </div>
</template>

<script>
import utils from '@/utils/main.utils';

export default {
    inheritAttrs: false,
    name: 'BInput',
    props: {
        modelValue: { type: String },
        debounce: { type: Number, default: 0 },
        size: { type: String, default: null },
        label: { type: String, default: '' },
        parentClass: { type: String, default: 'mb-3' },
        horizontal: { type: Boolean, default: false },
        labelColClass: { type: String, default: 'col-sm-auto' },
        inputColClass: { type: String, default: 'col-sm' },
    },
    data() {
        return {
            classSize: this.size ? `form-control-${this.size}` : '',
            classLabel: this.size && this.horizontal ? `col-form-label-${this.size}` : '',
            id: `input-${utils.makeId()}`,
            timer: null,
        };
    },
    methods: {
        changeValue(event) {
            const value = event.target.value;
            clearTimeout(this.timer);
            if (this.debounce === 0) this.$emit('update:modelValue', value);
            else {
                this.timer = setTimeout(() => {
                    this.$emit('update:modelValue', value);
                }, this.debounce);
            }
        },
    },
};
</script>
