const DELETE_VALUE = (state, payload) => {
    const values = { ...state.values };
    delete values[payload];

    state.values = values;
};

const UPDATE_CONTEXT_VALUE = (state, payload) => {
    state.context[payload.key] = payload.value;
};

const UPDATE_VALUE = (state, payload) => {
    state.values = { ...state.values, [payload.key]: payload.value };
};

const UPDATE_VALUES = (state, payload) => {
    state.values = { ...state.values, ...payload };
};

export default {
    DELETE_VALUE,
    UPDATE_CONTEXT_VALUE,
    UPDATE_VALUE,
    UPDATE_VALUES,
};
