'use strict';

import { view } from '@/functions';

const prefix = '/dashboard';
export default [
	{
		path: prefix,
		name: 'dashboard',
		component: () => view('dashboard/index')
	},
	{
		path: `${prefix}/profile`,
		name: 'profile',
		component: () => view('dashboard/profile')
	},
]; 