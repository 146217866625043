'use strict';

import { view } from '@/functions';

const prefix = '/payouts';
export default [
    {
        path: prefix,
        name: 'payouts',
        meta: {
            scopes: ['read_payouts', 'write_payouts']
        },
        component: () => view('payouts/index'),
    },
];
