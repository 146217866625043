<template>
    <div class="box_user">
        <ui-link weight="medium" :to="{ name: 'accounts-info', query: { id: account.id } }" target="_blank">
            <span v-if="account.first_name" class="font-weight-600">{{ account.first_name }} {{ account.last_name || '' }}</span>
            <span v-else class="font-weight-600">{{ account.email }}</span>
        </ui-link>
    </div>
    <p class="information">{{ account.phone || '#' + account.id }} | {{ account.email }} {{ account.name ? `| ${account.name}` : "" }}</p>
</template>

<script>
import UiLink from '@/components/ui/Link';
export default {
    components: {
        UiLink
    },
    props: {
        account: {
            type: Object,
            required: true
        }
    },
}
</script>

<style scss>
.box_user {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.information {
    font-size: 13px;
    color: #6c757d;
}
</style>