'use strict';

import { view } from '@/functions';

const prefix = '/activations';
export default [
    {
        path: prefix,
        name: 'activations',
        meta: {
            scopes: ['read_activations', 'write_activations']
        },
        component: () => view('activations/index'),
    },
    {
        path: `${prefix}/info`,
        name: 'activations-info',
        meta: {
            scopes: ['read_activations', 'write_activations']
        },
        component: () => view('activations/info'),
    },
];
