'use strict';

import { view } from '@/functions';

const prefix = '/admins';
export default [
    {
        path: prefix,
        name: 'admins',
        meta: {
            scopes: ['read_admins', 'write_admins']
        },
        component: () => view('admins/index')
    },
    {
        path: `${prefix}/:id/edit`,
        name: 'edit-admin',
        meta: {
            scopes: ['write_admins']
        },
        component: () => view('admins/edit')
    },
    {
        path: `${prefix}/invite`,
        name: 'invite-admin',
        meta: {
            scopes: ['write_admins']
        },
        component: () => view('admins/invite')
    },
];
