import actions from '@/store/modules/dynamic-forms/billing-information/actions';
import getters from '@/store/modules/dynamic-forms/billing-information/getters';
import mutations from '@/store/modules/dynamic-forms/billing-information/mutations';
import state from '@/store/modules/dynamic-forms/billing-information/state';

export default {
    actions,
    getters,
    mutations,
    namespaced: true,
    state,
};
