'use strict';

import { view } from '@/functions';

const prefix = '/transfers';

export default [
    {
        path: prefix,
        name: 'transfers',
        meta: {
            scopes: ['read_transfers', 'write_transfers']
        },
        component: () => view('transfers/index'),
    },
    {
        path: `${prefix}/info`,
        name: 'transfers-info',
        meta: {
            scopes: ['read_transfers', 'write_transfers']
        },
        component: () => view('transfers/info'),
    }
]