'use strict';

import { view } from '@/functions';

const prefix = '/whitelist';
export default [
	{
		path: prefix,
		name: 'Whitelist',
		meta: {
			scopes: ['read_whitelist', 'write_whitelist']
		},
		component: () => view('whitelist/index')
	},
]; 