<template>
    <div 
        ref="modal"
        class="modal"
        aria-hidden="true"
        :class="[{ fade: !noFade }]" tabindex="-1" :aria-labelledby="title || $slots.title"
        :data-bs-backdrop="backdrop"
        :data-bs-keyboard="keyboard"
    >
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title"><slot name="title">{{ title }}</slot></h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <slot></slot>
                </div>
                <div class="modal-footer">
                    <slot name="footer">
                        <b-button variant="light" @click="hide">Close</b-button>
                        <b-button variant="primary" @click="primaryAction">Ok</b-button>
                    </slot>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import modalsMixin from '@/mixins/modals.mixin';

export default {
    name: 'BModal',
    mixins: [modalsMixin],
    props: {
        title: { type: String, default: '' },
        noFade: { type: Boolean, default: false },
        primaryAction: { type: Function, default: () => {} },
        backdrop: { type: [Boolean, String], default: true },
        keyboard: { type: Boolean, default: true },
    },
}
</script>