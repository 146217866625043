import Modal from 'bootstrap/js/src/modal';

export default {
    data() {
        return {
            modal: null,
        };
    },
    mounted() {
        const vm = this;
        const options = {
            focus: true,
        };
        this.modal = new Modal(this.$refs.modal, options);
        this.$refs.modal.addEventListener('hidden.bs.modal', function (event) {
            vm.$emit('hidden', event);
        })
    },
    methods: {
        show() {
            this.modal.show();
        },
        hide() {
            this.modal.hide();
        }
    }
};
