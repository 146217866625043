import Toast from 'bootstrap/js/src/toast';
import MainToast from '@/components/MainToast';

export default {
    install: (app) => {
        app.config.globalProperties.$toast = {
            show(data) {
                let toast = document.createElement('div');
                const toastClass = `toast-${data.type}`;
                toast.setAttribute('class', `toast align-items-center mt-3 me-3 ${toastClass}`);
                toast.setAttribute('role', 'alert');
                toast.setAttribute('aria-live', 'assertive');
                toast.setAttribute('aria-atomic', 'true');
                toast.setAttribute('data-bs-delay', '15000');
                toast.innerHTML = `
					<div class="d-flex">
						<div class="d-flex justify-content-between toast-body">
							<svg class="bi flex-shrink-0 me-1" width="15" height="20" role="img" aria-label="${data.type}:"><use xlink:href="#${data.icon}"/></svg>
			    			<span>${data.message}</span>
						</div>
						<button type="button" class="btn-close me-2 m-auto" data-bs-dismiss="toast" aria-label="Close"></button>
					</div>
				`;
                toast.addEventListener('hide.bs.toast', () => {
                    toast.remove();
                });
                document.getElementById('toast-content').append(toast);
                new Toast(toast).show();
            },
            success(message) {
                this.show({
                    type: 'success',
                    icon: 'check-circle-fill',
                    message: message || 'Operation was successful!',
                });
            },
            error(message) {
                this.show({
                    type: 'danger',
                    icon: 'exclamation-triangle-fill',
                    message: message || 'An error occurred in the process!',
                });
            },
        };
        app.component('main-toast', MainToast);
    },
};
