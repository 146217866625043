'use strict';

import { view } from '@/functions';

const prefix = '/accounts';
export default [
    {
        path: prefix,
        name: 'accounts',
        meta: {
            scopes: ['read_accounts', 'write_accounts']
        },
        component: () => view('accounts/index'),
    },
    {
        path: `${prefix}/info`,
        name: 'accounts-info',
        meta: {
            scopes: ['read_accounts', 'write_accounts']
        },
        component: () => view('accounts/info'),
    }
];
