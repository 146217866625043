import actions from '@/store/modules/global-filters/actions';
import getters from '@/store/modules/global-filters/getters';
import mutations from '@/store/modules/global-filters/mutations';
import state from '@/store/modules/global-filters/state';

export default {
    actions,
    getters,
    mutations,
    namespaced: true,
    state,
};
