<template>
    <main-toast/>
    <component :is="layout" />
</template>

<script>
    import Dashboard from '@/layouts/dashboard';
    import Blank from '@/layouts/blank';
    export default {
        components: {
            Dashboard,
            Blank
        },
        data() {
            return {
                layout: null
            }
        },
        watch: {
            $route(to) {
                this.layout = to.meta.layout|| 'Dashboard';
            }
        }
    };
</script>