<template>
    <div class="dropdown">
        <button class="dropdown-toggle" :class="[variantClass, toggleClass, getTypeButton, {
            'dropdown-toggle-no-caret': noCaret,
        }]" type="button" data-bs-toggle="dropdown" data-bs-boundary="window" :data-bs-auto-close="autoClose" aria-expanded="false">
            <slot name="button-content"></slot>
        </button>
        <ul class="dropdown-menu">
            <slot> </slot>
        </ul>
    </div>
</template>

<script>
export default {
    name: 'BDropdown',
    props: {
        variant: { type: String, default: 'primary' },
        toggleClass: { type: String, default: null },
        noCaret: { type: Boolean, default: false },
        customSelect: { type: Boolean, default: false},
        autoClose: { type: [Boolean, String], default: true, },
    },
    computed: {
        getTypeButton() {
            return this.customSelect ? 'form-select' : 'btn';
        },
    },
    data() {
        return {
            variantClass: `btn-${this.variant}`,
        };
    },
};
</script>
