import { view } from '@/functions';

const prefix = '/leads';

export default [
    {
        path: prefix,
        name: 'leads',
        meta: {
            scopes: ['read_leads', 'write_leads']
        },
        component: () => view('leads/index')
    },
    {
        path: `${prefix}/register`,
        name: 'register-leads',
        meta: {
            scopes: ['write_leads']
        },
        component: () => view('leads/register')
    },
    {
        path: `${prefix}/info`,
        name: 'leads-info',
        meta: {
            scopes: ['read_leads', 'write_leads']
        },
        component: () => view('leads/info')
    },
];
