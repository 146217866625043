'use strict';

import { view } from '@/functions';

const prefix = '/chargebacks';
export default [
    {
        path: prefix,
        name: 'chargebacks',
        meta: {
            scopes: ['read_chargebacks', 'write_chargebacks']
        },
        component: () => view('chargebacks/index')
    },
    {
        path: `${prefix}/info`,
        name: 'chargebacks-info',
        meta: {
            scopes: ['read_chargebacks', 'write_chargebacks']
        },
        component: () => view('chargebacks/info')
    },
];
