const mixin = {
    methods: {
        scrollToTop() {
            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        },
        showError(error, custom_message) {
            const message = custom_message || this.getErrorMessage(error);
            this.$toast.error(message);
        },
        getErrorMessage(error) {
            let message = error?.response?.data?.message;

            if (!message) {
                message = 'Something went wrong, try later';
            }

            if (process.env.NODE_ENV === 'development') {
                console.log('errortest', error);
            }

            return message;
        },
        scrollToErrors() {
            setTimeout(() => {
                const input = document.querySelector('.tw-bg-red-100.tw-text-red-500 .list-unstyled li').getBoundingClientRect();
                window.scroll({
                    top: input.top + document.documentElement.scrollTop - 150,
                    left: 0,
                    behavior: 'smooth',
                });
            }, 200);
        },
        print() {
            window.print();
        },
        currencyToNumber(value) {
            if (typeof value === 'number') return value;
            if (!value) return 0;
            const symbol = '.';
            const regex = new RegExp(`[^0-9\\${symbol}]`, 'g');
            value = value.replace(regex, '');
            return parseFloat(value);
        },
        _isEmpty(value) {
            if (!value) return true;
            if (Array.isArray(value) && !value.length) return true;
            if (typeof value === 'object' && !Object.keys(value).length) return true;
            return false;
        }
    },
};

export default mixin;
