<template>
    <div class="error-state">
        <div class="error-state__card">
            <img class="error-state__image" src="@/assets/img/errors/warning.svg" alt="Forbidden" />
            <div class="error-state__text">
                <h1 class="error-state__title"> {{ error.status }} {{ error.error }}</h1>
                <p class="error-state__info"> {{ error.message }}</p>
                <p class="text-muted"> {{  'Please contact your administrator.'}}</p>
                <ui-button class="error-state__button" @click="$router.go(-1)" variant="link"> 
                    {{ 'Back'}} 
                </ui-button>
            </div>  
        </div>
    </div>
</template>

<script>
import UiButton from '@/components/ui/buttons/Button.vue';

export default {
    name: 'ErrorState',
    components: { UiButton },
    props: {
        error: {
            type: Object,
            default: () => ({ message: 'An error occurred while fetching data. Please try again later.' })
        },
        message: {
            type: String,
            default: 'An error occurred while fetching data. Please try again later.'
        }
    }
}
</script>

<style lang="scss">
.error-state {
    display: flex;
    flex-direction: column;
    align-items: center !important;
    justify-content: center;

    @media(max-width: 576px) {
        background-color: #ffffff;
        box-shadow: none;
    }

    &__card {
        margin-top: 100px;
        float: center;
        width: calc(100% - 10px);
        box-sizing: border-box;
        transition: 300ms all;
        padding: 25px 30px;

        @media (max-width: 998px) {
            padding: 20px 15px;
        }

        @media (max-width: 768px) {
            width: 100%;
            padding: 20px 15px;
        }

        &--open {
            width: calc(100% - 250px);

            @media (max-width: 768px) {
                width: 100%;
            }
        }
    }

    &__image {
        display: flex;
        justify-content: center;
        height: 50%;
        width: 10%;
        object-fit: cover;
        margin: 0 auto;
    }

    &__title {
        color: $danger;
        font-weight: 500;
        font-size: 25px;
    }

    &__text {
        padding: 1rem;
        text-align: center;
        margin-bottom: 1rem;
    }

    &__info {
        color: $general-black;
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 0;
    }

    &__button {
        width: 20%;
    }
}
</style>