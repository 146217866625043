'use strict';

import { view } from '@/functions';

const prefix = '/';

export default [
    { 
        path: prefix, 
        redirect: '/accounts'
    },
    { 
        path: `${prefix}login`, 
        name: 'login',
        component: () => view('main/login'),
        meta: {
            layout: 'Blank'
        } 
    },
    { 
        path: `${prefix}update-password`, 
        name: 'update-password',
        component: () => view('main/update-password'),
        meta: {
            layout: 'Blank'
        } 
    },
    { 
        path: `${prefix}recovery-password`, 
        name: 'recovery-password',
        component: () => view('main/recovery-password'),
        meta: {
            layout: 'Blank'
        } 
    },
    {
        path: `${prefix}expired-password`,
        name: 'expired-password',
        component: () => view('main/expirePassword'),
        meta: {
            layout: 'Blank'
        } 
    }
];
