'use strict';

import { view } from '@/functions';

const prefix = '/activitys';
export default [
    {
        path: prefix,
        name: 'activity',
        meta: {
            scopes: ['read_activity', 'write_activity']
        },
        component: () => view('activitys/index'),
    },
    {
        path: `${prefix}/info`,
        name: 'activity-info',
        meta: {
            scopes: ['read_activity', 'write_activity']
        },
        component: () => view('activitys/info'),
    },
];
