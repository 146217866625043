'use strict';

import { view } from '@/functions';

const prefix = '/withdrawals';
export default [
	{
		path: prefix,
		name: 'withdrawals',
		meta: {
			scopes: ['read_withdrawals', 'write_withdrawals']
		},
		component: () => view('withdrawals/index')
	},
]; 