const state = {
    context: {
        locales: [],
        states: [],
        suburbs: [],
    },
    values: {},
};

export default state;
