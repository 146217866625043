import Tooltip from 'bootstrap/js/src/tooltip';

export default {
    name: 'b-tooltip',
    created(el, binding, vnode, prevVnode) {
        if (binding.value) {
            el.setAttribute('data-bs-toggle', 'tooltip');
            el.setAttribute('title', binding.value);
            el.setAttribute('data-bs-placement', 'top');
            const tooltip = new Tooltip(el, {
                boundary: document.body,
            });
        }
    },
};